import singleActionTypes from "../actionTypes/single";


export const getSingle = (endpoint, criteria) => ({
    type: singleActionTypes.GET_SINGLE, 
    payload: { endpoint, criteria }
    
})

export const putSingle = (endpoint, payload, single) => ({
    type: singleActionTypes.PUT_SINGLE, 
    payload: { endpoint, payload, single }
    
})

export const postSingle = (endpoint, payload, single) => ({
    type: singleActionTypes.POST_SINGLE, 
    payload: { endpoint, payload, single }
})


export const deleteSingle = (endpoint, payload, single) => ({
    type: singleActionTypes.DELETE_SINGLE, 
    payload: { endpoint, payload, single }
    
})

export const download = (endpoint, criteria, ext = '.xlsx') => ({
    type: singleActionTypes.DOWNLOAD,
    payload: { endpoint, criteria, ext }
});

export const setSingle = (data) => ({
    type: singleActionTypes.SET_SINGLE, 
    payload: { data }
    
})

export const setResponse = (data) => ({
    type: singleActionTypes.SET_RESPONSE, 
    payload: { data }
    
})

export const setLoading = (single) => ({
    type: singleActionTypes.SET_LOADING, 
    payload: { single }
    
})

export const clearSingle = (single) => ({
    type: singleActionTypes.CLEAR_SINGLE, 
    payload: {single}    
    
})

export const clearAll = () => ({
    type: singleActionTypes.CLEAR_ALL,     
    
})

export const clearList = (single) => ({
    type: singleActionTypes.CLEAR_SINGLE,     
    payload: { single }
})