import { useContext, useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';

import AllowedComponent from '../components/auth/AllowedComponent';
import * as notificationsActions from './../actions/notificationActions';
import { BarsContext } from 'providers/bars-context';

import './AuthRoutes.css';

import { Box } from '@mui/material';

import TGSideBar from 'TGComponents/layout/TGSideBar/TGSideBar';
import TGTopBar from 'TGComponents/layout/TGTobBar/TGTopBar';
import menuItems from 'data/navs/MenuItems';

const AuthRoutes = () => {
  // Contexto traz validações para deixar a tela fullscreen usado em visao geral no credenciamento
  const { closeTopBar, closeSidebar, fullScreen, backgroundColor } =
    useContext(BarsContext);
  const navigate = useNavigate();

  const location = useLocation();

  let loc = location.pathname.toLowerCase().replace('/admin/', '');

  const {
    type,
    message,
    show,
    redirectTo,
    loggedIn,
    complete,
    //menus,
    user,
    isAdm,
    meeting,
    sidemenuOpen,
  } = useSelector((state) => ({
    loggedIn: state.user.uuid,
    complete: state.user.complete || false,
    user: state.user,
    isAdm: state.user?.super_admin || false,
    meeting: state.meeting,
    //menus: state.user.menus,
    type: state.notification.type,
    message: state.notification.message,
    show: state.notification.show,
    redirectTo: state.notification.redirectTo,
    sidemenuOpen: state.application.sidemenuOpen,
  }));
  const isUserAllowed = (menu, user, isAdm) => {
    const isAdmMenu = menu?.adm ?? true;
    if (isAdm && isAdmMenu) {
      return true;
    } else {
      const roles = user?.roles || [];
      const gestor = user?.super_admin || false;
      const rolesPermitidas = menu?.roles || [];
      const boEvo = menu?.evo || false;
      const eagle = menu?.eagle || false;
      const exclusivoEvo = menu?.exclusivoEvo || false;
      const exclusivoGlobal = menu?.exclusivoGlobal || false;
      const exclusivoTour = menu?.exclusivoTour || false;
      const exclusivoCarisma = menu?.exclusivoCarisma || false;
      
      const boUsuarioVariasProdutoras =
        user?.boUsuarioVariasProdutoras || false;

      //empresas dos users que podem acessar o menu Indicações (evo)
      const empresas_evo = [
        56, 58, 11, 57, 19, 61, 50, 60, 49, 63, 59, 9, 45, 62, 74, 75, 76, 82,
        84, 23,
      ];
      const empresas_eagle = [
        45, 49, 50, 56, 57, 58, 59, 60, 61, 62, 63, 74, 75, 76, 82, 84, 89, 91,
        92, 93, 94, 96, 98, 99, 101, 102, 106, 107, 108, 109, 110, 112, 114,
        116, 117, 118, 119, 121, 122, 123, 124, 130, 132, 141, 145, 146, 147,
        149, 154, 156, 158, 161, 171, 166, 255
      ];
      const _isPermitido =
        roles.find((r) =>
          rolesPermitidas.find((rp) => {
            if (!isAdm) {
              if (eagle && empresas_eagle.includes(r.id_produtora)) {
                return true;
              } else if (
                boEvo &&
                (empresas_evo.includes(r.id_produtora) ||
                  boUsuarioVariasProdutoras) &&
                !exclusivoEvo
              ) {
                //Produtora evo
                return true;
              } else {
                if (exclusivoEvo && r.id_produtora == 9) {
                  //Produtora evo
                  return true;
                } else {
                  if (
                    !exclusivoGlobal &&
                    !exclusivoTour &&
                    !exclusivoCarisma &&
                    (r.super_admin || !boEvo) &&
                    r.slug === rp
                  ) {
                    //Produtoras diferentes ou superadmin
                    return true;
                  } else {
                    if (exclusivoGlobal && r.id_produtora == '153') {
                      //Produtora global
                      return true;
                    } else if (exclusivoTour && r.id_produtora == '55') {
                      //Produtora tour
                      return true;
                    } else if (exclusivoCarisma && r.id_produtora == '173') {
                      //Produtora tour
                      return true;
                    } else {
                      return false;
                    }
                  }
                }
              }
            } else {
              return r?.adm;
            }
          })
        ) || gestor;
      return _isPermitido;
    }
  };

  const filterMenuItems = (items, user, isAdm) => {
    const roles = user?.roles || [];
    return items
      .filter((item) => isUserAllowed(item, user, isAdm))
      .map((item) => {
        if (item.children) {
          item.children.find((r, indice) => {
            item.children[indice].to = r.to.replace(
              '#UUIDPRODUTORA#',
              roles?.[0]?.uuid_produtora + '&c=1'
            );
          });
          return {
            ...item,
            children: filterMenuItems(item.children, user, isAdm),
          };
        }
        return item;
      });
  };

  const dispatch = useDispatch();

  const notify = () => {
    const options = {
      position: 'top-right',
      theme: 'colored',
      onClose: () => dispatch(notificationsActions.clear()),
    };

    if (type === 'success') {
      toast.success(message, {
        ...options,
      });
    } else if (type === 'warning') {
      toast.warning(message, {
        ...options,
      });
    } else if (type === 'info') {
      toast.info(message, {
        ...options,
      });
    } else {
      toast.error(message, {
        ...options,
      });
    }
  };

  useEffect(() => {
    if (show) {
      if (redirectTo) {
        dispatch(notificationsActions.clearRedirect());
        navigate(redirectTo, { replace: true });
      } else {
        notify();
      }
    }
  }, [show, redirectTo]);

  const filteredMenuItems = filterMenuItems(menuItems(user), user, isAdm);
  const noTopBar = closeTopBar && fullScreen;

  return loggedIn ? (
    <Box
      height={'100%'}
      width='100%'
      position={'relative'}
      backgroundColor={{ xs: '#fff', sm: backgroundColor || '#EEEEEE' }}
    >
      {!noTopBar && <TGTopBar sx={{ zIndex: 999, maxWidth: '100vw' }} />}
      <Box
        mt={noTopBar ? '0px' : ['72px', '72px', '96px']}
        padding={
          noTopBar ? { xs: '0', md: '0px' } : { xs: '0', md: '20px 0 20px' }
        }
        backgroundColor={{ xs: '#fff', lg: backgroundColor || '#EEEEEE' }}
      >
        <Box display='flex' width='100%'>
          {!closeSidebar &&
          (user.roles.length > 0 || user.super_admin === true) > 0 ? (
            <TGSideBar menuItems={filteredMenuItems} close={closeSidebar} />
          ) : null}
          <Box
            width='100%'
            display='flex'
            flexDirection='column'
            overflow='auto'
          >
            <Box flex='1'>
              <AllowedComponent permission={loc} redirect={true}>
                <Outlet />
              </AllowedComponent>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Navigate to='/login' />
  );
};

export default AuthRoutes;
