import React, { useState, useRef, useEffect } from 'react';
import { useFormikContext, Field, useField } from 'formik';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import FocusTrap from 'focus-trap-react';
import { DayPicker } from 'react-day-picker';
import { usePopper } from 'react-popper';
import { Typography } from '@mui/material';

import flatfy from '../../utils/flatfy';

import moment from 'moment';

import Input from './Input';

import InputMask from 'react-input-mask';

import 'react-day-picker/dist/style.css';
import TGButton from 'TGComponents/global/TGButton';
import { CalendarTodayOutlined } from '@mui/icons-material';
import TGTextField from './../../TGComponents/global/TGTextField';

const DateInput = ({
  errors = {},
  name,
  label,
  value = undefined,
  hour = false,
  handleClear = false,
  defaultDate = null,
  ...props
}) => {
  const { values, handleChange, handleBlur, setFieldValue } =
    useFormikContext();

  const [field] = useField(props);

  const [selected, setSelected] = useState(
    defaultDate ? new Date(defaultDate) : null
  );

  //const [inputValue, setInputValue] = useState("");
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef(null);
  const buttonRef = useRef(null);
  const clearRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);

  const hasErrors = flatfy(errors, name) || false;

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    //buttonRef?.current?.focus();
  };

  const handleButtonClick = () => {
    setIsPopperOpen(true);
  };

  const handleDaySelect = (date) => {
    setSelected(date);
    if (date) {
      setFieldValue(name, format(date, 'dd/MM/y'));
      setFieldValue(`${name}-flat`, format(date, 'dd/MM/y'));

      closePopper();
    } else {
      setFieldValue(name, '');
      setFieldValue(`${name}-flat`, '');
    }
  };
  //
  //  useEffect(() => {
  //    let val = flatfy(values, name);
  //
  //    if (val) {
  //      if (val.includes('/')) {
  //        const date = Date.parse(moment(val, 'DD-MM-YYYY').format('YYYY-MM-DD'));
  //        setSelected(date);
  //        setFieldValue(name, val);
  //        setFieldValue(`${name}-flat`, val);
  //      } else {
  //        setSelected(Date.parse(moment(val).format('YYYY-MM-DD')));
  //        setFieldValue(name, moment(val).format('DD/MM/YYYY'));
  //        setFieldValue(`${name}-flat`, moment(val).format('DD/MM/YYYY'));
  //      }
  //    }
  //  }, [values]);

  useEffect(() => {
    // Initialize with defaultDate only if provided and not yet set
    if (defaultDate) {
      
      const date = new Date(defaultDate);
      setSelected(date);
      setFieldValue(name, format(date, 'dd/MM/y'));
      setFieldValue(`${name}-flat`, format(date, 'dd/MM/y'));
    }
  }, [defaultDate]);

  return !hour ? (
    <div className='has-label form-group'>
      <Typography fontSize='12px' color='#555' mb='4px' htmlFor={props.id}>
        {label}
      </Typography>

      <div
        ref={popperRef}
        className={`form-control d-flex align-items-center ${
          hasErrors ? 'is-invalid' : ''
        }`}
      >
        <Input
          placeholder='dd/mm/aaaa'
          className='p-0 m-0 dateinput'
          style={{
            display: 'inline-block',
            flex: '1',
            outline: 'none',

            border: 'none',
            height: '35px',
            width: '100%',
          }}
          onFocus={(e) => {
            e.target.style.borderColor = 'transparent !important';
            e.target.style.outline = 'none !important';
          }}
          name={`${name}-flat`}
          id={`${name}-flat`}
          value={values[`${name}-flat`]}
          mask='99/99/9999'
          onChange={handleChange}
          onBlur={(e) => {
            const val = e.target.value;

            if (
              !/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/.test(
                val
              )
            ) {
              setFieldValue(`${name}-flat`, '');
              setFieldValue(name, '');
            } else {
              setFieldValue(name, val);
            }

            handleBlur(e);
          }}
          autoComplete='off'
        />
        <button
          ref={buttonRef}
          type='button'
          className='btn p-0 m-0'
          aria-label='Pick a date'
          onClick={handleButtonClick}
        >
          <CalendarTodayOutlined />
        </button>
      </div>
      <div className='invalid-feedback'>{(hasErrors || [])?.join(' ')}</div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
          }}
        >
          <div
            tabIndex={-1}
            style={{
              ...popper.styles.popper,
              background: '#fff',
              border: '1px solid #333',
              zIndex: '999',
            }}
            className='dialog-sheet'
            {...popper.attributes.popper}
            ref={setPopperElement}
            role='dialog'
          >
            <Field
              as={DayPicker}
              locale={ptBR}
              initialFocus={isPopperOpen}
              mode='single'
              defaultMonth={selected}
              selected={selected}
              onSelect={handleDaySelect}
              autoComplete='off'
              {...field}
              {...props}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  ) : (
    <div className='row'>
      <div className='col-sm-6'>
        <div className='has-label form-group'>
          <Typography fontSize='12px' color='#555' mb='4px' htmlFor={props.id}>
            {label}
          </Typography>
          <div
            ref={popperRef}
            className={`form-control d-flex align-items-center ${
              hasErrors ? 'is-invalid' : ''
            }`}
          >
            {values[`${name}`] || ''}
            <Input
              placeholder='00:00'
              className='p-0 m-0'
              style={{
                display: 'inline-block',
                flex: '1',
                border: 'none',
                height: '35px',
              }}
              name={`${name}-flat`}
              id={`${name}-flat`}
              mask='99/99/9999'
              value={values[`${name}`] || ''} // Make sure it's controlled
              onChange={(e) => {
                const val = e.target.value;

                // Validate as user types
                if (
                  /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/.test(
                    val
                  )
                ) {
                  setFieldValue(name, val); // Set main field value if valid
                } else {
                  setFieldValue(name, ''); // Clear main field if invalid
                }

                setFieldValue(`${name}-flat`, val); // Update the flat field value as user types
              }}
              autoComplete='off'
            />

            <button
              ref={buttonRef}
              type='button'
              className='btn p-0 m-0'
              aria-label='Pick a date'
              onClick={handleButtonClick}
            >
              <CalendarTodayOutlined />
            </button>
            {handleClear !== false && (
              <button
                type='button'
                className='btn p-0 m-0 ms-2'
                aria-label='Pick a date'
                onClick={handleClear}
              >
                <i className='bi bi-trash'></i>
              </button>
            )}
          </div>
          <div className='invalid-feedback'>{(hasErrors || [])?.join(' ')}</div>
          {isPopperOpen && (
            <FocusTrap
              active
              focusTrapOptions={{
                initialFocus: false,
                allowOutsideClick: true,
                clickOutsideDeactivates: true,
                onDeactivate: closePopper,
              }}
            >
              <div
                tabIndex={-1}
                style={{
                  ...popper.styles.popper,
                  background: '#fff',
                  border: '1px solid #333',
                  zIndex: '999',
                }}
                className='dialog-sheet'
                {...popper.attributes.popper}
                ref={setPopperElement}
                role='dialog'
              >
                <Field
                  as={DayPicker}
                  name={name}
                  locale={ptBR}
                  initialFocus={isPopperOpen}
                  mode='single'
                  defaultMonth={selected}
                  selected={selected}
                  onSelect={handleDaySelect}
                  autoComplete='off'
                  {...field}
                  {...props}
                />
              </div>
            </FocusTrap>
          )}
        </div>
      </div>
      <div className='col-sm-6'>
        <TGTextField
          errors={errors}
          mask='99:99'
          type='text'
          name={hour}
          label='Horário'
          id={hour}
          onChange={(e) => {
            const val = e.target.value;

            if (parseInt(val.slice(0, 2) || 0) > 23) return;
            if (parseInt(val.slice(3, 5) || 0) > 59) return;

            e.target.value = val;

            handleChange(e);
          }}
          onBlur={(e) => {
            if (!/^([0-1] ? [0-9]|2[0-3]):[0-5][0-9]$/.test(values[hour])) {
              setFieldValue(hour, '');
            }

            handleBlur(e);
          }}
        />
      </div>
    </div>
  );
};

export default DateInput;
