import { useMemo } from 'react'
import { store } from 'store'

export const useInternational = () => {
  const { user } = store.getState()
  const stripe_account_id = user?.stripe_account_id || null
  const group = user?.grupo || {}
  const roles = user?.roles || []
  const uuid_pais_user = user?.uuid_pais || null

  const uuid_pais =
    roles.length > 0 &&
    roles[0]?.uuid_pais !== null &&
    roles[0]?.uuid_pais !== undefined
      ? roles[0].uuid_pais
      : uuid_pais_user

  const isInternational = useMemo(() => {
    if (!uuid_pais) return true
    return uuid_pais !== '64d89475-41cf-4351-b193-73c0c4512d4f' // Verifica se é diferente de Brasil
  }, [uuid_pais])

  const active = useMemo(() => {
    return uuid_pais !== null
  }, [uuid_pais])

  return { isInternational, group, roles, stripe_account_id, active }
}
