export const FlagIcon = ({ width = 24, height = 24, color = '#D72B2B' }) => {
  return (
    <svg
      width='12'
      height='15'
      viewBox='0 0 12 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.504 7.73566L9.61802 6.86422C9.40742 6.68564 9.28397 6.42135 9.27671 6.12849C9.26219 5.80705 9.3929 5.48562 9.63254 5.2499L10.504 4.39273C11.2592 3.64986 11.5424 2.93556 11.3028 2.37126C11.0704 1.81411 10.3515 1.50696 9.29124 1.50696H1.70256V0.892659C1.70256 0.599796 1.45566 0.356934 1.15792 0.356934C0.860185 0.356934 0.613281 0.599796 0.613281 0.892659V14.1072C0.613281 14.4001 0.860185 14.643 1.15792 14.643C1.45566 14.643 1.70256 14.4001 1.70256 14.1072V10.6214H9.29124C10.3369 10.6214 11.0413 10.3071 11.281 9.74285C11.5206 9.17855 11.2447 8.47139 10.504 7.73566Z'
        fill={color}
      />
    </svg>
  );
};
