import React from 'react';
export const Arrows = ({ size = 21, color = '#222222' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M5.90041 2.03125C6.08224 2.03125 6.24895 2.08857 6.37878 2.18391C6.41336 2.20927 6.44553 2.2375 6.47481 2.26839C6.48516 2.27931 6.49513 2.29052 6.50469 2.30202L9.50043 5.83982C9.74896 6.13332 9.68181 6.54969 9.35044 6.76982C9.01907 6.98995 8.54897 6.93047 8.30044 6.63697L6.65039 4.68836V17.3545C6.65039 17.7213 6.3146 18.0187 5.90039 18.0187C5.48618 18.0187 5.15039 17.7213 5.15039 17.3545V4.68843L3.50044 6.63696C3.25191 6.93046 2.78181 6.98995 2.45044 6.76982C2.11907 6.5497 2.05191 6.13333 2.30043 5.83982L5.29437 2.3041C5.43079 2.1387 5.65144 2.03125 5.90041 2.03125Z"
        fill={color}
      />
      <path
        d="M14.3789 18.0187C14.5225 17.9766 14.6501 17.8965 14.7431 17.7867L17.7431 14.2439C17.9916 13.9504 17.9244 13.534 17.5931 13.3139C17.2617 13.0937 16.7916 13.1532 16.5431 13.4467L14.8925 15.396L14.8498 2.69554C14.8498 2.32866 14.514 2.03125 14.0998 2.03125C13.6856 2.03125 13.3498 2.32866 13.3498 2.69554L13.3925 15.3946L11.7431 13.4467C11.1627 12.7613 9.9627 13.5585 10.5431 14.2439L13.5255 17.7659C13.6587 17.9362 13.8794 18.0487 14.13 18.0523C14.1341 18.0524 14.1383 18.0524 14.1425 18.0524"
        fill={color}
      />
      <path
        d="M14.2607 18.0356C14.3056 18.03 14.3363 18.0312 14.3789 18.0187L14.2607 18.0356Z"
        fill={color}
      />
    </svg>
  );
};